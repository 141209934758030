import React from 'react'
import {PageProps, Link, withPrefix} from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import '../../static/KMS Schoolkalender 2023_2024 - nov 2023.pdf'
import '../../static/Schoolgids KMS 2023-2024 - nov. 2023.pdf'

const Team = (props: PageProps) => (
  <Layout>
    <SEO title="Lestijden" />
    <div>
      <p>
        <a href={withPrefix('Schoolgids KMS 2023-2024 - nov. 2023.pdf')} target="_blank" className="usefulLinks" rel="noopener">
          Schoolgids 2023/2024
        </a>
      </p>
      <p>
        <a href={withPrefix('KMS Schoolkalender 2023_2024 - nov 2023.pdf')} target="_blank" className="usefulLinks" rel="noopener">
          Schooljaarkalender 2023/2024
        </a>
      </p>

      <h5>Lestijden 2023-2024</h5>

      <br />
      <h6>Lessen op school (St. George International & Preschool)</h6>
      <table>
        <tbody>
          <tr>
            <td rowspan="6">Zaterdag</td>
            <td>10:00 - 11:30</td>
            <td>peuters / groep 1</td>
            <td>
              meester Hans-Peter <br /> meester Pieter
            </td>
          </tr>
          <tr>
            <td>10:00 - 12:00</td>
            <td>groep 2</td>
            <td>meester Mark</td>
          </tr>
          <tr>
            <td>10:00 - 12:00</td>
            <td>groep 4 / 5</td>
            <td>
              juf Margo <br /> meester Twan
            </td>
          </tr>
          <tr>
            <td>13:00 - 15:00</td>
            <td>groep 6 / 7 / 8</td>
            <td>
              juf Margo
              <br /> juf Milena
            </td>
          </tr>
          <tr>
            <td>13:00 - 15:00</td>
            <td>VO3</td>
            <td>
              juf Margo <br />
              meester Hans-Peter
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <h6>Lessen online (via Google Meet)*</h6>
      <table>
        <tbody>
          <tr>
            <td rowspan="3">Woensdag</td>
            <td>18:15 - 19:00</td>
            <td>groep 4</td>
            <td>juf Margo</td>
          </tr>
          <tr>
            <td>19:00 - 19:45</td>
            <td>groep 7</td>
            <td>juf Margo</td>
          </tr>
        </tbody>
      </table>
      <br />
      <table>
        <tbody>
          <tr>
            <td rowspan="4">Donderdag</td>
            <td>16:00 - 16:45</td>
            <td>groupe 8</td>
            <td>juf Margo</td>
          </tr>
          <tr>
            <td>18:30 - 19:15</td>
            <td>group 6</td>
            <td>juf Margo</td>
          </tr>
          <tr>
            <td>19:15 - 20:00</td>
            <td>groep 5</td>
            <td>juf Margo</td>
          </tr>
          <tr>
            <td>20:00 - 20:45</td>
            <td>VO3</td>
            <td>juf Margo</td>
          </tr>
        </tbody>
      </table>
      <p className="remark">* Dag en tijdstip zijn onder voorbehoud. Voor actuele lestijden kunt u contact opnemen met de school.</p>
      <br />
      <h6>Interesse & aanmelden</h6>
      <p>
        Als u belangstelling heeft om uw kind aan te melden op onze school of graag meer informatie wilt, kunt u contact opnemen met Margo de Kruijf,
        schooldirecteur: <a href="mailto:margo@nederlandseschool.bg">margo@nederlandseschool.bg</a>. In overleg wordt een telefonisch
        kennismakingsgesprek georganiseerd en een proefles afgesproken. Na de proefles wordt met ouders en school geëvalueerd en de vervolgstappen
        besproken. Meer informatie over voorwaarden voor deelname kunt u vinden in hoofdstuk 4 van de schoolgids.
      </p>
    </div>
  </Layout>
)

export default Team
